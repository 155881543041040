<template>
  <footer id="footer2">
    Enworkspace.com&nbsp;©️2024 EnLinkage Co.,ltd All Rights Reserved.
    Powered By <a style="color:#fff" href="http://www.enable-software.com/">Enable</a>
  </footer>
</template>

<script>
export default {
  name: "CommonFooter"
};
</script>
<style scoped>
#footer2 { 
  width:100%;
  text-align: center;
  line-height: 55px;
  height: 55px;
  background-color: #333a40;
  color: #fff;
  font-size: 12px;
  margin-top: 55px;
}
</style>
