<template>
  <ul class="rightNav">
    <li style="line-height: 22px;">
      <el-dropdown @command="handleCommand" v-if="accountInfo.name">
        <span class="el-dropdown-link" style="background-color: rgb(236, 245, 255);padding: 5px 8px;border-radius: 12px;">
          <i class="el-icon-user-solid" style="font-size: 18px;margin-right: 5px;color: #409EFF;"></i>
          {{
            accountInfo.name
          }}
        </span>
        <el-dropdown-menu slot="dropdown" >
          <el-dropdown-item :command="ROUTER_PATH.ACCOUNT_INFO"
            >会員情報</el-dropdown-item
          >
          <el-dropdown-item :command="ROUTER_PATH.ACCOUNT_RESET_PASSWORD"
            >パスワード変更</el-dropdown-item
          >
          <el-dropdown-item :command="ROUTER_PATH.LOGIN_OUT"
            >ログアウト</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      </li>

      <li style="line-height: 22px;">

      <el-dropdown @command="handleCommand2">
            
        <span class="el-dropdown-link">
          {{lang}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="ja">日本語</el-dropdown-item>
          <!--el-dropdown-item command="en">English</el-dropdown-item>
          <el-dropdown-item command="zh">中文</el-dropdown-item-->
        </el-dropdown-menu>
      </el-dropdown>

    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";
import { ROUTER_PATH } from "@/constants/common.js";
export default {
  name: "HeaderRightNav",
  data: () => {
    return {
      ROUTER_PATH: ROUTER_PATH,
      lang: ""
    };
  },
  mounted() {
    if (this.$i18n.locale === "zh") {
      this.lang = "中文";
    } else {
      this.lang = "日本語";
    }
  },
  methods: {
    handleCommand(command) {
      this.$router.push(command);
    },
    handleCommand2(command) {
      // if (command == "zh") {
      //   this.lang = "中文";
      //   this.$i18n.locale = "zh";
      //   //this.$cookie.set("lang","zh");
      // } else if (command == "en") {
      //   this.lang = "English";
      //   this.$i18n.locale = "en";
      //   //this.$cookie.set("lang","jp");
      // } else {
        this.lang = "日本語";
        this.$i18n.locale = "ja";
        //this.$cookie.set("lang","jp");
      // }
    },
  },
  computed: {
    ...mapState(["accountInfo"])
  }
};
</script>

<style>
.rightNav {
  display: flex;
  list-style: none;
}
.rightNav svg {
  margin-right: 8px;
}
.rightNav .active span {
  color: #0055f9;
}
.rightNav .active span:hover {
  color: #0055f9;
}
.rightNav li {
  color: #999;
  cursor: pointer;
  font-size: 14px;
  margin-left: 30px;
}
.rightNav li span {
  font-size: 14px;
  color: #999;
}
.rightNav li:hover {
  color: #333;
}
.rightNav li span:hover {
  color: #333;
}
</style>
