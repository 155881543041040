import axiosRequest from "@/utils/axiosRequest";

export function parseImage(data) {
  return axiosRequest({
    url: `/kcon/image/parse`,
    data,
    method: "post"
  });
}

export function listApp(data) {
  return axiosRequest({
    url: `/kcon/k/app/list`,
    data,
    method: "post"
  });
}

export function listField(data) {
  return axiosRequest({
    url: `/kcon/k/app/field/list`,
    data,
    method: "post"
  });
}

export function listRobots(data) {
  return axiosRequest({
    url: `/kcon/robots`,
    data,
    method: "post"
  });
}

export function addRobot(data) {
  return axiosRequest({
    url: `/kcon/save/robot/name`,
    data,
    method: "post"
  });
}

export function saveConfig(data) {
  return axiosRequest({
    url: `/kcon/save/robot`,
    data,
    method: "post"
  });
}

export function deleteRobot(data) {
  return axiosRequest({
    url: `/kcon/del/robot`,
    data,
    method: "post"
  });
}

export function getConfig(data) {
  return axiosRequest({
    url: `/kcon/get/robot`,
    data,
    method: "post"
  });
}

export function listTask(data) {
  return axiosRequest({
    url: `/kcon/tasks`,
    data,
    method: "post"
  });
}

export function saveExport(data) {
  return axiosRequest({
    url: `/kcon/k/app/set/pdf`,
    data,
    method: "post"
  });
}

export function getExport(data) {
  return axiosRequest({
    url: `/kcon/k/app/get/pdf`,
    data,
    method: "post"
  });
}
  

export function listExport(data) {
  return axiosRequest({
    url: `/kcon/k/app/get/pdfs`,
    data,
    method: "post"
  });
}

export function deleteExport(data) {
  return axiosRequest({
    url: `/kcon/k/app/del/pdf`,
    data,
    method: "post"
  });
}


