//import { Message } from "element-ui";
export function isValidResponse(response) {
    if (response.code === "000") {
        if (response.data) {
            return true;
        } else {
            return false;
        }
    } else {
        // Message.error(response.message);
        return false;
    }
};
