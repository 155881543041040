<template>
  <header id="header">
    <div class="container">
      <img src="../../assets/images/logo.png" width="120" />
      <nav>
        &nbsp;
      </nav>
      <HeaderRightNav />
    </div>
  </header>
</template>

<script>
import HeaderRightNav from "./HeaderRightNav.vue";
import { isNullOrUndefinedOrEmpty } from "@/utils/stringHelper.js";
import { getUserInfo } from "@/api/account";
import { mapState, mapMutations } from "vuex";
import { mutationsType } from "@/store/mutations-type.js";
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "CommonHeader",
  components: { HeaderRightNav },
  mounted() {
    if (
      !isNullOrUndefinedOrEmpty(
        localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN)
      ) &&
      !isNullOrUndefinedOrEmpty(
        localStorage.getItem(LOCAL_STORAGE_KEY.RPA_USER_ID)
      ) &&
      isNullOrUndefinedOrEmpty(this.accountInfo.userID)
    ) {
      this.refreshUserInfo();
    }
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    ...mapMutations([mutationsType.SET_ACCOUNT_INFO]),
    refreshUserInfo() {
      getUserInfo({
        //userID: localStorage.getItem(LOCAL_STORAGE_KEY.RPA_USER_ID)
      }).then(response => {
        this[mutationsType.SET_ACCOUNT_INFO](response.data);
      });
    }
  }
};
</script>

<style scoped>
#header {
  z-index: 1;
  height: 60px;
  flex-shrink:0;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;  
}
#header .container {
  width: 1064px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

#header nav {
  flex: 1;
  margin-left: 50px;
}
</style>
