<template>
  <div class="main">
    <CommonHeader/>
    <div class="title_section">
      帳票出力一覧
      <el-button @click="add" type="primary" ><i class="el-icon-plus"></i>帳票出力新規</el-button>
    </div>
    <div class="search_section">
      <div>
        <el-input placeholder="キーワード" class="input-with-select" style="width: 480px;" type="primary" v-model="searchCond.key">
          <el-select slot="prepend" placeholder="検索項目" style="width: 120px;" v-model="searchCond.keyType">
            <el-option label="帳票名" value="name"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" type="primary" @click="handleSearch" :clearable="true"></el-button>
        </el-input>
      </div>
      <div>
        <!--el-radio-group v-model="searchCond.robotStatus" style="margin-left:18px;" @change="handleSearch">
          <el-radio-button label="">全部</el-radio-button>
          <el-radio-button label="0">未設定</el-radio-button>
          <el-radio-button label="1">設定済</el-radio-button>
        </el-radio-group-->
      </div>
    </div>
    <div class="data_table">
      <el-table
      :data="docList" width="100%">
        <el-table-column
          label="ID"
          width="80px">
          <template slot-scope="scope">
            <span>
              {{ scope.row.id }}
            </span>
           </template>
        </el-table-column>
        <el-table-column
          label="帳票名">
          <template slot-scope="scope">
            <span style="font-weight: bold;font-size: 16px;">
              {{ scope.row.exportName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="アプリ">
          <template slot-scope="scope">
          <span>
            {{ scope.row.appName }}
          </span>
          </template>
        </el-table-column>
        <el-table-column
          label="テンプレート">
          <template slot-scope="scope">
          <span @click="download(scope.row.templateUrl)">
            {{ scope.row.templateName }}
          </span>
          </template>
        </el-table-column>
        <el-table-column
          label="作成時間"
          width="160px">
          <template slot-scope="scope">
          <span>
            {{ scope.row.createTime }}
          </span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="220px">
          <template slot-scope="scope">
            <el-button size="mini" type="" @click="handleSetting(scope.row.appId)" plain>設定</el-button>
            <el-button size="mini" type="warning" @click="handleDelete(scope.row.id)" plain>删除</el-button>
          </template>
        </el-table-column>
        <template slot="empty">
          <el-empty description="該当データがありません" style="margin:auto"></el-empty>
        </template>
      </el-table>
    </div>
    <div style="flex:1"></div>
    <CommonFooter />
    <el-dialog title="帳票出力" :visible.sync="dialogFormVisible">
      <el-form :model="form" label-width="150px" label-position="left">
        <el-form-item label="帳票名" prop="exportName">
          <el-input v-model="form.exportName" maxlength="50" style="width: 80%;"></el-input>
        </el-form-item>
        <el-form-item label="Kintoneアプリ" prop="appId">
            <el-select 
              v-model="form.appId" 
              placeholder="Kintoneアプリを選択" 
              style="width: 80%;"
              filterable
              remote
              reserve-keyword
              :remote-method="handleSearchApp"
              :loading="loading"
              @change="handleAppChange">
              <el-option
                v-for="(item, index) in this.kappList"
                :key="index"
                :label="item.name"
                :value="item.appId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="アプリ名" prop="appName" v-show="false">
            <el-input v-model="form.appName" maxlength="50" style="width: 80%;"></el-input>
          </el-form-item>
          <el-form-item label="出力ボタン名" prop="btnName">
            <el-input v-model="form.btnName" maxlength="50" style="width: 80%;"></el-input>
          </el-form-item>
          <el-form-item label="出力ファイル名" prop="outputName">
            <el-input v-model="form.outputName" maxlength="50" style="width: 80%;"></el-input>
          </el-form-item>

          <el-upload
            ref="upload"
            action="https://enworkspace.com/kcon/k/app/set/pdf"
            :on-remove="handleRemove"
            :file-list="fileList"
            :auto-upload="false"
            :on-success="uploadSuccess"
            :data="{...form}"
            :headers="{'Authorization':auth}"
            >
            <el-button slot="trigger" size="small" type="primary">テンプレート選択</el-button>
            <div slot="tip" class="el-upload__tip">1MB以下のExcelファイル（XLSX）を選択してください。只能上传xls文件，且不超过500kb</div>
          </el-upload>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="handleAdd">確認</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CommonHeader from "@/components/common/CommonHeader.vue";
import CommonFooter from "@/components/common/CommonFooter.vue";
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";

import { isValidResponse } from "@/utils/commonUtils";
import { ROUTER_PATH } from "@/constants/common.js";
import { mapState } from "vuex";
import { listExport,getExport,saveExport,deleteExport } from "@/api/kintone";
import {listApp} from "@/api/kintone";

export default {
  name: "DocList",
  components: {
    CommonHeader,
    CommonFooter
  },
  data: () => {
    return {
      searchCond:{
        key:"",
        keyType:"name",
        robotStatus:"",
      },
      loading: false,
      kappList:[],
      filterStatus: [],
      docList:[],
      dialogFormVisible: false,
      form:{},
      fileList:[],
      auth:""
    };
  },
  mounted() {
    listExport({
    }).then(response => {
      this.docList = response.data;
    });

    const token = localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN);
    if (token) {
        this.auth = `Bearer ${token}`;
    }
  },
  created: function () {
  },
  computed: {
    ...mapState(["accountInfo"]),
  },
  methods: {
    handleSearch() {
      listExport({
      }).then(response => {
        this.docList = response.data;
      });
    },
    handleSetting(id) {
      this.dialogFormVisible= true;
      getExport({appId:id}).then(response => {
        this.form = {
          exportName:response.data.exportName,
          appId:response.data.appId,
          btnName:response.data.btnName,
          outputName:response.data.exportName
        };

      })
    },
    add(){
      this.dialogFormVisible= true;
      this.form = {
        exportName:"",
        appId:"",
        appName:"",
        btnName:"",
        outputName:""
      };
    },
    handleSearchApp(query) {
      this.loading = true;
      if (query !== '') {
        listApp({
          appName: query
        }).then(response => {
          this.kappList = response.data;
          this.loading = false;
        });
      } else {
        this.kappList = [];
        this.loading = false;
      }
    },
    handleAppChange(val) {
      this.kappList.map((item,idx)=>{
        if(item.appId == val) {
          this.form.appName = item.name;
        }
      })
      // listField({
      //   appId:val
      // }).then(response => {
      //   this.form.appName = response.data;
      // });
    },
    handleAdd(){
      this.$refs.upload.submit();
      // saveExport({
      //   ...this.form
      // }).then(response => {
      //   if(isValidResponse(response)){
      //     listExport({
      //     }).then(response => {
      //       this.docList = response.data;
      //     });
      //     this.$message({
      //       message: "追加しました",
      //       type: "success"
      //     });
      //   }
      //   this.dialogFormVisible = false;
      // });
    },
    uploadSuccess(response, file, fileList){
      console.log(response, file, fileList);
      if(response.code != "200") {
        this.$message({
          message: "追加失敗しました",
          type: "warning"
        });
      }
      listExport({
      }).then(response => {
        this.docList = response.data;
      });
      this.$message({
        message: "追加しました",
        type: "success"
      });
      this.dialogFormVisible = false;
    },
    download(url) {
      window.open(url,"_blank");
    },
    handleDelete(id){
      deleteExport({
        exportId:id
      }).then(response => {
        if(isValidResponse(response)){
          listExport({
          }).then(response => {
            this.docList = response.data;
          });
          this.$message({
            message: "削除しました",
            type: "success"
          });
        }
      });
    },
    
    handleStart(id){
      addRobot({
        id:id,
        status:10
      }).then(response => {
        if(isValidResponse(response)){
          listRobots({
          }).then(response => {
            this.docList = response.data;
          });
          this.$message({
            message: "起動しました",
            type: "success"
          });
        }
        this.dialogFormVisible = false;
      });
    },
    handleStop(id){
      addRobot({
        id:id,
        status:99
      }).then(response => {
        if(isValidResponse(response)){
          listRobots({
          }).then(response => {
            this.docList = response.data;
          });
          this.$message({
            message: "停止しました",
            type: "success"
          });
        }
        this.dialogFormVisible = false;
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    
  },
};
</script>
<style scoped>
.title_section {
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content: space-between;
  width: 1060px;
  margin-top: 32px;
  font-size: 18px;
  font-weight: bold;
}
.search_section {
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
  background-color: #fff;
  width: 1024px;
  margin-top: 16px;
  padding: 18px;
  border-radius: 12px 12px 0px 0px;
}
.data_table {
  width: 1024px;
  margin-top: 4px;
  padding: 18px;
  border-radius: 0px 0px 12px 12px;
  background-color: #fff;
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>

